// import { useApiClient } from "./useApiClient";
import { type EditProfileInterface } from "~/models/user";

export const useUserApi = () => {
  // const apiClient = useApiClient();
  const { $axios } = useNuxtApp();

  const egitProfile = async (data: EditProfileInterface, id: number) => {
    return await $axios.post(`/api/users/${id}`, {
      ...data,
      _method: "PUT",
    });
  };

  const uploadAvatar = async (file: any, id: number) => {
    if (!file) return;

    const formData = new FormData();
    formData.append("avatar", file);

    try {
      return await $axios.post(`/api/users/${id}/avatar`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      throw error;
    }
  };

  const changePasssword = (
    data: {
      old_password: string;
      password: string;
      password_confirmation: string;
    },
    id: number
  ) => {
    return $axios.post(`/api/users/${id}/change-password`, data);
  };

  const changeEmail = (
    data: { email: string; password: string },
    id: number
  ) => {
    return $axios.post(
      `/api/users/${id}/email/verification-notification`,
      data
    );
  };

  const addCarInGarage = async (data: any, id: number) => {
    return $axios.post(`/api/users/${id}/cars`, data);
  };

  const getCarsInGarage = async (id: number) => {
    return $axios.get(`/api/users/${id}/cars`);
  };

  const getCarInGarage = async (id: number, carId: number) => {
    return $axios.get(`/api/users/${id}/cars/${carId}`);
  };

  const deleteCarInGarage = async (id: number, carId: number) => {
    return $axios.delete(`/api/users/${id}/cars/${carId}`);
  };

  const editCarById = async (id: number, carId: number, data: any) => {
    return await $axios.put(`/api/users/${id}/cars/${carId}`, {
      ...data,
      _method: "PUT",
    });
  };

  return {
    egitProfile,
    uploadAvatar,
    changePasssword,
    changeEmail,
    addCarInGarage,
    getCarsInGarage,
    getCarInGarage,
    deleteCarInGarage,
    editCarById,
  };
};
